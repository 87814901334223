var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8e7a4060dbe3e0d6a35823085dbe23f8baf3635a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "http://06aeda6013da432dbf49807a93a9a348@sentry-v2.ensiniodev.com:9900/3",
  sampleRate: 0.5,
  tracesSampleRate: 0.1,
  debug: false,
  replaysOnErrorSampleRate: 1.0,
  // This sets the replays sample rate to be 100%
  replaysSessionSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
      maskAllInputs: true,
      networkDetailAllowUrls: [".cloud", window?.location?.origin],
      networkDetailDenyUrls: ["/api/v1/login", "/credit_card", "password"],
    }),
  ],
});
