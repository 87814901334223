import { useCallback, useEffect, useState } from "react";

import { usePathname } from "next/navigation";

import insertCustomScript from "shared/utils/entities/customScripts/insertCustomScript";
import { getCloudEndpoint } from "shared/utils/entities/tenant/getCloudEndpoint";
import { getCustomScripts } from "shared/utils/getCustomStripts";

import { captureException, captureMessage } from "@sentry/nextjs";

type ICustomScriptContent = {
  id: number;
  type: string;
  script: string;
};

type ICustomScript = {
  head: ICustomScriptContent[];
  body: ICustomScriptContent[];
};

interface IUseCustomScripts {
  slug: string;
}
const useCustomScripts = ({
  slug,
}: IUseCustomScripts): { customScripts: ICustomScript } => {
  const pathname = usePathname();

  const isInConsolePage = pathname?.includes("/console");
  const isAbleToLoadCustomScripts =
    slug && slug !== "g" && !slug.includes("browse") && !isInConsolePage;

  const [customScripts, setCustomScrips] = useState<ICustomScript>(
    {} as ICustomScript,
  );

  const loadCustomScripts = async (slug: string) => {
    const endpoint = getCloudEndpoint(slug);
    const response = await getCustomScripts(endpoint);

    const head = response?.data?.filter(
      (data) =>
        data?.type?.includes("head") &&
        !data?.script?.includes("dashboard-script"),
    );

    const body = response?.data?.filter((data) => data?.type?.includes("body"));
    body?.forEach(({ script }) => insertCustomScript(script, true));

    setCustomScrips({ head, body });
  };

  const handleCustomScripts = useCallback(() => {
    try {
      if (!isAbleToLoadCustomScripts) return;

      loadCustomScripts(slug).catch(() => loadCustomScripts(slug));
    } catch (error) {
      const errorMessage =
        "[_app]: Fetch or insertion of custom scripts failed [at loadCustomScripts() useEffect].";

      console.log(errorMessage, error);
      captureException(error);
      captureMessage(errorMessage);
    }
  }, [isAbleToLoadCustomScripts, slug]);

  useEffect(() => {
    handleCustomScripts();
  }, [handleCustomScripts]);

  return { customScripts };
};

export default useCustomScripts;
