import React from "react";

import Head from "next/head";

import { TenantData } from "shared/providers/context/initialStates/tenancy";
import insertCustomScript from "shared/utils/entities/customScripts/insertCustomScript";

interface IAppHead {
  tenancy: TenantData;
  pageName: string;
  query: { tenant?: string };
  customScripts: { head: { script: string }[] };
  customPageTenancy?: {
    image_thumbnail: string;
    title: string;
    seo_title: string;
    seo_description: string;
    seo_keyword: string;
  } | null;
}

const AppHead: React.FC<IAppHead> = ({
  tenancy,
  pageName = "",
  query,
  customScripts,
  customPageTenancy,
}) => {
  const settings = tenancy?.settings;
  const generalTheme = settings ? settings?.["general-theme"] : null;

  const themeFavicon = generalTheme?.themeFavicon || "";
  const themeFaviconCustom = generalTheme?.themeFaviconCustom || "";
  const themeLogoIcon = generalTheme?.themeLogoIcon || "";

  const site = settings?.site;

  const pagesTitle = {
    blog: settings?.blog.blogAreaTitle,
    bundles: settings?.bundles.bundleAreaTitle,
    courses: settings?.courses.courseAreaTitle,
    media: settings ? settings["media-space"].mediaSpaceAreaTitle : null,
    social: settings ? settings["social-network"].communityAreaTitle : null,
    account: "Minha conta",
    authentication: "Acesse sua conta",
    categories: "Categorias",
    collections: "Coleções",
    myaccess: "Meus acessos",
    orders: "Pedidos",
    payment: "Checkout",
    plans: "Planos",
    search: "Pesquisa",
    subscriptions: "Assinaturas",
    userscommunity: "Usuários",
    rewards: "Loja de recompensas",
  };

  const customImage = {
    blog: settings?.blog?.blogThumbnailImage || "",
    bundles: settings?.bundles?.bundleThumbnailImage || "",
    collections: settings?.courses?.collectionsThumbnailImage || "",
    courses: settings?.courses?.courseThumbnailImage || "",
    media: settings ? settings["media-space"].mediaSpaceThumbnailImage : "",
    social: settings ? settings["social-network"].communityThumbnailImage : "",
  };

  const title = pagesTitle[pageName.toLowerCase()] || pageName;
  const imageThumbnail =
    customImage[pageName.toLowerCase()] || site?.seoSocialImage;

  const favIcon =
    themeFavicon === "custom" && themeFaviconCustom
      ? themeFaviconCustom
      : themeLogoIcon;

  return (
    <Head>
      {themeFavicon !== "none" && favIcon && (
        <>
          <link rel="icon" type="image/png" sizes="32x32" href={favIcon} />
          <link rel="icon" type="image/png" sizes="16x16" href={favIcon} />
        </>
      )}

      {tenancy && (
        <>
          <title>{`${customPageTenancy?.title || title} | ${
            site?.siteName
          }`}</title>

          <meta
            name="title"
            content={customPageTenancy?.seo_title || site?.seoSiteTitle}
          />
          <meta
            name="description"
            content={
              customPageTenancy?.seo_description || site?.seoSiteDescription
            }
          />
          <meta
            name="keywords"
            content={customPageTenancy?.seo_keyword || site?.seoSiteKeywords}
          />

          {site?.allowSearchEngines !== "1" && (
            <meta name="robots" content="noindex"></meta>
          )}

          <meta property="fb:app_id" content="1555269294950305" />
          <meta
            property="og:title"
            content={customPageTenancy?.seo_title || site?.seoSiteTitle}
          />
          <meta property="og:url" content={`${query?.tenant}.ensinio.com`} />
          <meta
            property="og:description"
            content={
              customPageTenancy?.seo_description || site?.seoSiteDescription
            }
          />
          <meta
            property="og:image"
            content={customPageTenancy?.image_thumbnail || imageThumbnail}
          />
          <meta property="og:type" content="video_lecture" />
          <meta property="og:site_name" content={site?.siteName} />
          <meta property="og:locale" content="pt_BR" />
        </>
      )}

      {customScripts?.head?.map(({ script }) => insertCustomScript(script))}
    </Head>
  );
};

export default AppHead;
